<template>
    <div class="examine examine-detail">
        <div class="header-two">
            <MainNav></MainNav>
        </div>
        <div class="wrapper">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }"
                    >首页</el-breadcrumb-item
                >
                <el-breadcrumb-item :to="{ path: '/examine' }"
                    >产品审核列表</el-breadcrumb-item
                >
                <el-breadcrumb-item>审核详情</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="content shadow">
                <div class="title">
                    <h1>{{ product.name }} [ {{ product.model }} ]</h1>
                    <div class="time">
                        修改时间：<span>{{ modify.modifyTime }}</span>
                    </div>
                </div>
                <el-table
                    :data="modify.details"
                    class="main-table"
                    stripe
                    border
                    header-row-class-name="table-header"
                >
                    <el-table-column
                        prop="name"
                        label="修改名称"
                        width="180"
                    ></el-table-column>
                    <el-table-column prop="state" label="修改前" align="center">
                        <template slot-scope="scope">
                            <div
                                class="before"
                                v-if="scope.row.colName != 'imgs'"
                            >
                                {{ scope.row.beforeValue }}
                            </div>
                            <div
                                class="before"
                                v-if="scope.row.colName == 'imgs' && scope.row.beforeValue"
                            >
                                <el-image 
                                    v-for="(
                                        img, idx
                                    ) in scope.row.beforeValue.split(',')"
                                    :key="idx"
                                    style="width: 100px; height: 100px"
                                    :src="img"
                                    :preview-src-list="
                                        scope.row.beforeValue.split(',')
                                    "
                                >
                                </el-image>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="修改后" align="center">
                        <template slot-scope="scope">
                            <div
                                class="after"
                                v-if="scope.row.colName != 'imgs'"
                            >
                                {{ scope.row.afterValue }}
                            </div>
                            <div
                                class="after"
                                v-if="scope.row.colName == 'imgs' && scope.row.afterValue"
                            >
                                <el-image
                                    v-for="(
                                        img, idx
                                    ) in scope.row.afterValue.split(',')"
                                    :key="idx"
                                    style="width: 100px; height: 100px"
                                    :src="img"
                                    :preview-src-list="
                                        scope.row.afterValue.split(',')
                                    "
                                >
                                </el-image>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    label-width="100px"
                    class="demo-ruleForm"
                >
                    <el-form-item label="审核结果" prop="result" v-if="modify.status == 1">
                        <el-radio-group
                            v-model="ruleForm.status"
                            @change="radioChange"
                        >
                            <el-radio-button label="-2" border
                                >不通过</el-radio-button
                            >
                            <el-radio-button label="2" border
                                >通过</el-radio-button
                            >
                        </el-radio-group>
                    </el-form-item>
                    <!-- <el-form-item
                        label="不通过原因"
                        prop="desc"
                        v-if="descShow"
                    >
                        <el-input
                            type="textarea"
                            v-model="ruleForm.desc"
                            placeholder="请输入不通过原因"
                        ></el-input>
                    </el-form-item> -->
                    <el-form-item class="btn" v-if="modify.status == 1">
                        <el-button
                            class="main-btn"
                            type="warning"
                            @click="submitForm('ruleForm')"
                            >立即审核</el-button
                        >
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <MainFooter></MainFooter>
    </div>
</template>

<script>
import http from "../../http.js";
import MainNav from "../../components/header.vue";
import MainFooter from "../../components/footer.vue";
export default {
    components: {
        MainNav,
        MainFooter,
    },
    data() {
        return {
            modify: {
                id: "",
                productId: "",
            },
            product: {
                id: "",
            },
            descShow: true,
            ruleForm: {
                id: "",
                status: -2,
                desc: "",
            },
            rules: {
                status: [
                    {
                        required: true,
                        message: "请选择审核结果",
                        trigger: "change",
                    },
                ],
                // desc: [{
                // 	required: true,
                // 	message: '请填写不通过原因',
                // 	trigger: 'blur'
                // }]
            },
            tableData: [],
        };
    },
    created() {
        if (this.$route.query.id) {
            this.modify.id = this.$route.query.id;
            this.ruleForm.id = this.modify.id;
            this.getModify();
        }
    },
    methods: {
        getModify() {
            http.fetchGet("/api/admin/product/modify", {
                id: this.modify.id,
            }).then((res) => {
                if (res.data.code == 0) {
                    this.modify = res.data.data;
                    this.getProduct();
                }
            });
        },
        getProduct() {
            http.fetchGet("/api/admin/product", {
                id: this.modify.productId,
            }).then((res) => {
                if (res.data.code == 0) {
                    this.product = res.data.data;
                }
            });
        },
        radioChange(value) {
            if (value == 1) {
                this.descShow = true;
            } else {
                this.descShow = false;
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$confirm("确定要审核吗?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            http.fetchPost(
                                "/api/admin/product/modify/verify",
                                this.ruleForm
                            ).then((res) => {
                                if (res.data.code == 0) {
                                    this.$message({
                                        type: "success",
                                        message: "审核成功!",
                                    });
                                    this.$router.push("/examine");
                                } else {
                                    this.$message.error(res.data.desc);
                                }
                            });
                        })
                        .catch(() => {});
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss">
	@import '~@/assets/css/examine.scss';
</style>
